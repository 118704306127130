import React, { forwardRef } from 'react';
import { SwiperSlide } from 'swiper/react';
import { Divider, Row, Col, Grid } from '@components/Grid';
import styles from './styles.module.scss';
import Img from 'gatsby-image';
import CustomSwiper from '@components/CustomSwiper';

const GraphicDesign = ({ imageRowBanner, imageBanner, sliderImages, imageGrid }, ref) => {
    return (
        <section ref={ref}>
            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={styles.graphicDesignHeading}>
                            <h2>3. Graphic Design</h2>
                            <p>
                                Here is a selection of digital and print material that Element
                                Softworks as produced for The Gundies 2021.
                            </p>
                        </div>
                    </Col>
                </Row>

                <Divider margin={5} />
                <Divider xsMargin={0} smMargin={5} />

                <div className={styles.graphicDesignBannerWrapper}>
                    <Row>
                        <Col xs={12} md={5}>
                            <Img fluid={imageRowBanner} imgStyle={{ objectFit: 'cover' }} />
                        </Col>

                        <Col xs={12} md={7}>
                            <div className={styles.graphicDesignBannerTextContainer}>
                                <Img fluid={imageBanner} imgStyle={{ objectFit: 'cover' }} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Grid>

            <Divider xsMargin={0} smMargin={5} />

            <CustomSwiper>
                {sliderImages?.map((image) => (
                    <SwiperSlide key={image?.src}>
                        <Img fluid={image} imgStyle={{ objectFit: 'cover' }} />
                    </SwiperSlide>

                ))}
            </CustomSwiper>

            <Divider margin={5} />
            <Divider xsMargin={0} smMargin={5} />

            <Img fluid={imageGrid} imgStyle={{ objectFit: 'cover' }} />

            <Divider margin={5} />
            <Divider xsMargin={0} smMargin={5} />

            <Grid>
                <Row>
                    <Col xs={12}>
                        <h2 className={styles.graphicDesignContainedHeading}>
                            We also produced a large set of animated assets to use in their
                            livestream.
                        </h2>
                    </Col>
                </Row>

                <Divider xsMargin={4} smMargin={5} />
                <Divider xsMargin={0} smMargin={5} />

                <Row>
                    <Col xs={12}>
                        <div className={styles.livestreamVideo}>
                            <iframe
                                width='560'
                                height='349'
                                src='https://www.youtube.com/embed/2_XL1LD9frc?autoplay=0'
                                frameBorder='0'
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen
                            />
                        </div>
                    </Col>
                </Row>
            </Grid>
        </section>
    );
};

export default forwardRef(GraphicDesign);
