import React, { useEffect, useRef, useState } from 'react';
import { Divider, Row, Col, Grid } from '@components/Grid';
import styles from './styles.module.scss';
import Img from 'gatsby-image';
import SVG from 'react-inlinesvg';
import Button from '@components/Button';
import { Link } from 'gatsby';
import TooltipGlossary from '@components/TooltipGlossary';
import ScrollTextWithImage from '@components/ScrollTextWithImage';

const SiteOverview = ({
    siteOverviewPwa,
    imageBg,
    imageQuickConverting,
    iconAdmin,
    iconBalancing,
    iconProtection,
    iconDatabase,
}) => {
    return (
        <section className={styles.siteOverviewWrapper}>
            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={styles.siteOverviewHeading}>
                            <h2>
                                The Gundies is a progressive web app (PWA) that is sleek, fast and
                                modern.
                            </h2>
                        </div>
                    </Col>
                </Row>

                <Divider margin={5} />
                <Divider XsMargin={0} mdMargin={5} />

                <div className={styles.siteOverviewRowWrapper}>
                    <Row>
                        <Col xs={12} md={6}>
                            <SVG src={siteOverviewPwa} alt="smartphone 3D graphic" />
                        </Col>
                        <Col xs={12} md={6}>
                            <p>
                                The Gundies platform is built as a progressive web app (PWA) using
                                the React Javascript framework. This allows takes advantage of the
                                latest technologies to combine the best of web and mobile apps.
                                Think of it as a website built using web technologies but that acts
                                and feels like an app.
                            </p>
                            <p>
                                Recent advancements in the browser allow users to install web apps
                                to their home screen, receive push notifications and even work
                                offline.
                            </p>
                            <p>
                                Progressive web apps take advantage of the much larger web
                                ecosystem, plugins and community and the relative ease of
                                maintaining a website.
                            </p>
                        </Col>
                    </Row>
                </div>

                <Divider margin={5} />
                <Divider XsMargin={0} smMargin={5} />
                <Divider xsMargin={0} smMargin={5} />

                <ScrollTextWithImage imageFluid={imageQuickConverting}>
                    <div className={styles.quickAndConvertingContent}>
                        <h2>Quick, converting and engaging.</h2>
                        <p>
                            Our sites outperform the competition{' '}
                            <Link to="#glossary=lighthouse">Lighthouse</Link> tests for speed and
                            accessibility.
                        </p>
                        <p> Want to know more about our web-development methodology?</p>
                        <Link to="/service/web-platforms/web-development">
                            <Button text="Read more" />
                        </Link>
                    </div>
                </ScrollTextWithImage>
            </Grid>

            <Divider margin={5} />
            <Divider XsMargin={0} smMargin={5} />

            <Grid>
                <Row>
                    <Col xs={12}>
                        <div className={styles.siteOverviewHeading}>
                            <h2>How do you manage 50,000 users?</h2>
                        </div>
                    </Col>
                </Row>

                <Divider margin={5} />
                <Divider xsMargin={0} smMargin={5} />

                <Row>
                    <Col xs={12} sm={3}>
                        <div className={styles.siteOverviewIcon}>
                            <img src={iconAdmin} alt="ES icon admin" />
                        </div>
                    </Col>
                    <Col xs={12} sm={9}>
                        <p>
                            With a admin panel custom built for the application. On here the Forge
                            Relation team can view, edit, accept/decline users and view monthly
                            statistics to see how the website is performing. This gives a detailed
                            but clear over-site of how each part of the website is performing.
                        </p>
                    </Col>

                    <Divider margin={5} />

                    <Col xs={12} sm={3}>
                        <div className={styles.siteOverviewIcon}>
                            <img src={iconBalancing} alt="ES icon admin" />
                        </div>
                    </Col>
                    <Col xs={12} sm={9}>
                        <p>
                            With a scalable architecture, allowing database and backend server
                            numbers to grow and shrink based on the demand of the day. At peak times
                            the load-balanced system can increased by up to 600% to deal with extra
                            demand, and can scale down safely after to keep costs low.
                        </p>
                    </Col>

                    <Divider margin={5} />

                    <Col xs={12} sm={3}>
                        <div className={styles.siteOverviewIcon}>
                            <img src={iconProtection} alt="ES icon admin" />
                        </div>
                    </Col>
                    <Col xs={12} sm={9}>
                        <p>
                            With an enterprise-level network proxy and firewall. We provided the TLS
                            1.3, full SSL encryption, DNSSEC and a DNS Proxy along side industry
                            leading DDoS protection and our network firewall (with utilises a global
                            threat database) to protect against threads each website is in safe
                            hands.
                        </p>
                    </Col>

                    <Divider margin={5} />

                    <Col xs={12} sm={3}>
                        <div className={styles.siteOverviewIcon}>
                            <img src={iconDatabase} alt="ES icon admin" />
                        </div>
                    </Col>
                    <Col xs={12} sm={9}>
                        <p>
                            With a custom built database for handling million of votes, hundreds of
                            thousands of users and other complex data structure such as badges,
                            awards, events, logging and more. Allowing us to use connectable data
                            across the whole platform in real time.
                        </p>
                    </Col>
                </Row>
            </Grid>
        </section>
    );
};

export default SiteOverview;
