import React, { useRef } from 'react';
import { Divider, Row, Col, Grid } from '@components/Grid';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '@components/Layout';
import Hero from '@views/case-studies/the-gundies/Hero';
import styles from './styles.module.scss';
import ProjectOverview from '@views/case-studies/ProjectOverview';
import Contents from '@views/case-studies/the-gundies/Contents';
import BrandingStrategy from '@views/case-studies/the-gundies/BrandingStrategy';
import DesignAndDevelopment from '@views/case-studies/the-gundies/DesignAndDevelopment';
import SiteOverview from '@views/case-studies/the-gundies/SiteOverview';
import Marketing from '@views/case-studies/the-gundies/Marketing';
import GraphicDesign from '@views/case-studies/the-gundies/GraphicDesign';
import ReadMore from '@views/case-studies/ReadMore';
import SEO from '@components/Seo';

const TheGundies = ({ data }) => {
    // console.log('data?.hero?.childImageSharp?.fluid', data?.hero?.childImageSharp?.fluid);
    // console.log('svg', data?.contents);
    const $brandingStrategy = useRef(null);
    const $website = useRef(null);
    const $marketing = useRef(null);
    const $graphicDesign = useRef(null);

    return (
        <Layout>
            <SEO
                title="The Gundies • Case Studies"
                image={data?.gundiesPreview?.publicURL}
                description=" The Gundies is an all encompassing project including wed-design,
                                complex UX theory, web-development, branding, social media
                                managements, layout & print, emailing campaign."
            />
            <div className={styles.caseStudyWrapper}>
                <Hero
                    title="2 Million Visitor Ready"
                    text="We gave Forge Relations the tools required to reach over 2 million people for The Gundies 2021."
                    background={data?.hero?.childImageSharp?.fluid}
                />
                <ProjectOverview
                    client="Forge Relations"
                    name="Industry leading annual awards platform for firearms influencers."
                    backgroundColor="#1C1C1C"
                    description={
                        <p>
                            The Gundies is an all encompassing project including wed-design, complex
                            UX theory, web-development, branding, social media managements, layout &
                            print, emailing campaign.
                            <br /> <br /> This project lasted 4 months.
                        </p>
                    }
                />
                <Contents
                    svg={data?.contents?.publicURL}
                    svgAlt="a gun"
                    list={[
                        { title: '1. Branding Strategy', items: ['Goals'], ref: $brandingStrategy },
                        {
                            title: '2. Website',
                            items: ['User Experience Design (UX)', 'Web-design', 'Web-development'],
                            ref: $website,
                        },
                        {
                            title: '3. Marketing',
                            items: ['Social media marketing', 'Animation', 'Email campaign'],
                            ref: $marketing,
                        },
                        {
                            title: '4. Graphic Design',
                            items: [
                                'Digital brand assets',
                                'Print & Editorial',
                                'Livestream Assets',
                            ],
                            ref: $graphicDesign,
                        },
                    ]}
                />

                <Divider xsMargin={5} smMargin={0} />

                <BrandingStrategy
                    imageBg={data?.brandingStrategyBg?.childImageSharp?.fluid}
                    ref={$brandingStrategy}
                />
                <DesignAndDevelopment
                    imageSplash={data?.brandingStrategySplash?.childImageSharp?.fluid}
                    imagePlatformBg={data?.brandingStrategyPlatformBg?.childImageSharp?.fluid}
                    imagePlatformImage={data?.brandingStrategyPlatformImage?.childImageSharp?.fluid}
                    sliderImages={[
                        data?.designAndDevelopmentUx?.childImageSharp?.fluid,
                        data?.designAndDevelopmentUx02?.childImageSharp?.fluid,
                    ]}
                    badgesImage={data?.designAndDevelopmentUxBadges?.childImageSharp?.fluid}
                    psychologyImage={data?.designAndDevelopmentPsychology?.childImageSharp?.fluid}
                    uiImage={data?.designAndDevelopmentUi?.childImageSharp?.fluid}
                    ref={$website}
                />
                <SiteOverview
                    siteOverviewPwa={data?.siteOverviewPwa?.publicURL}
                    imageBg={data?.brandingStrategyBg?.childImageSharp?.fluid}
                    imageQuickConverting={data?.siteOverviewQuickConverting?.childImageSharp?.fluid}
                    iconAdmin={data?.siteOverviewEsIconAdmin?.publicURL}
                    iconBalancing={data?.siteOverviewEsIconBalancing?.publicURL}
                    iconProtection={data?.siteOverviewEsIconProtection?.publicURL}
                    iconDatabase={data?.siteOverviewEsIconDatabase?.publicURL}
                />

                <Divider xsMargin={0} smMargin={5} />

                <Marketing
                    imageGrid={data?.marketingGrid?.childImageSharp?.fluid}
                    imageGuidelines={data?.marketingGuidelines?.childImageSharp?.fluid}
                    imageTemp={data?.brandingStrategyBg?.childImageSharp?.fluid}
                    imageAnimationAttention={
                        data?.marketingAnimationAttention?.childImageSharp?.fluid
                    }
                    imageEmailTemplates={data?.marketingEmailTemplates?.childImageSharp?.fluid}
                    gif01={data?.marketingGif01?.publicURL}
                    gif02={data?.marketingGif02?.publicURL}
                    ref={$marketing}
                />
                <GraphicDesign
                    imageRowBanner={data?.graphicDesignRowBanner?.childImageSharp?.fluid}
                    imageBanner={data?.graphicDesignBanner?.childImageSharp?.fluid}
                    sliderImages={[
                        data?.graphicDesignSliderImage01?.childImageSharp?.fluid,
                        data?.graphicDesignSliderImage02?.childImageSharp?.fluid,
                        data?.graphicDesignSliderImage03?.childImageSharp?.fluid,
                    ]}
                    imageGrid={[data?.graphicDesignGrid?.childImageSharp?.fluid]}
                    ref={$graphicDesign}
                />
                <ReadMore />
            </div>
        </Layout>
    );
};

export const query = graphql`
    query {
        gundiesPreview: file(absolutePath: { regex: "/case-studies-the-gundies-preview.png/" }) {
            publicURL
        }
        hero: file(absolutePath: { regex: "/case-study-the-gundies-001.png/" }) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        contents: file(absolutePath: { regex: "/case-studies-the-gundies-contents-02.svg/" }) {
            publicURL
        }
        brandingStrategyBg: file(
            absolutePath: { regex: "/case-studies-the-gundies-branding-strategy-bg.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingStrategySplash: file(
            absolutePath: { regex: "/case-studies-the-gundies-branding-strategy-splash.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingStrategyPlatformBg: file(
            absolutePath: {
                regex: "/case-studies-the-gundies-branding-strategy-platform-background.png/"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        brandingStrategyPlatformImage: file(
            absolutePath: {
                regex: "/case-studies-the-gundies-branding-strategy-platform-image.png/"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        designAndDevelopmentUx: file(
            absolutePath: { regex: "/case-studies-the-gundies-design-and-development-ux-01.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        designAndDevelopmentUx02: file(
            absolutePath: { regex: "/case-studies-the-gundies-design-and-development-ux-02.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        designAndDevelopmentUxBadges: file(
            absolutePath: { regex: "/case-studies-the-gundies-design-and-development-badges.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        designAndDevelopmentPsychology: file(
            absolutePath: {
                regex: "/case-studies-the-gundies-design-and-development-psychology.png/"
            }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        designAndDevelopmentUi: file(
            absolutePath: { regex: "/case-studies-the-gundies-design-and-development-ui.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        siteOverviewPwa: file(absolutePath: { regex: "/case-studies-the-gundies-pwa.svg/" }) {
            publicURL
        }
        siteOverviewQuickConverting: file(
            absolutePath: { regex: "/case-studies-the-gundies-site-overview-quick-converting.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        siteOverviewEsIconAdmin: file(absolutePath: { regex: "/es-icon-admin.svg/" }) {
            publicURL
        }
        siteOverviewEsIconBalancing: file(absolutePath: { regex: "/es-icon-load-balancing.svg/" }) {
            publicURL
        }
        siteOverviewEsIconProtection: file(
            absolutePath: { regex: "/es-icon-cloud-protection.svg/" }
        ) {
            publicURL
        }
        siteOverviewEsIconDatabase: file(absolutePath: { regex: "/es-icon-database.svg/" }) {
            publicURL
        }
        marketingGrid: file(
            absolutePath: { regex: "/case-studies-the-gundies-marketing-grid.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        marketingGuidelines: file(
            absolutePath: { regex: "/case-studies-the-gundies-marketing-guidelines.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        marketingAnimationAttention: file(
            absolutePath: { regex: "/case-studies-the-gundies-marketing-animation-attention.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        marketingEmailTemplates: file(
            absolutePath: { regex: "/case-studies-the-gundies-marketing-email-templates.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        graphicDesignRowBanner: file(
            absolutePath: { regex: "/case-studies-the-gundies-graphic-design-row-banner.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        graphicDesignBanner: file(
            absolutePath: { regex: "/case-studies-the-gundies-graphic-design-banner.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        graphicDesignSliderImage01: file(
            absolutePath: { regex: "/case-studies-the-gundies-graphic-design-slider-image-01.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        graphicDesignSliderImage02: file(
            absolutePath: { regex: "/case-studies-the-gundies-graphic-design-slider-image-02.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        graphicDesignSliderImage03: file(
            absolutePath: { regex: "/case-studies-the-gundies-graphic-design-slider-image-03.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        graphicDesignGrid: file(
            absolutePath: { regex: "/case-studies-the-gundies-graphic-design-grid.png/" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        marketingGif01: file(
            absolutePath: { regex: "/case-studies-the-gundies-marketing-gif-01.gif/" }
        ) {
            publicURL
        }
        marketingGif02: file(
            absolutePath: { regex: "/case-studies-the-gundies-marketing-gif-02.gif/" }
        ) {
            publicURL
        }
    }
`;

export default TheGundies;
