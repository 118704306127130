import React from 'react';
import { Divider } from '@components/Grid';
import styles from './styles.module.scss';

import StartProjectCta from '@components/StartProjectCta';
import RecentCaseStudies from '@components/RecentCaseStudies';

const ReadMore = () => {
    return (
        <section className={styles.readMoreWrapper}>
            <Divider margin={5} />
            <Divider margin={5} />
            <div className={styles.recentProjectsWrapper}>
                <RecentCaseStudies />
            </div>
            <Divider margin={5} />
            <StartProjectCta />
        </section>
    );
};

export default ReadMore;
