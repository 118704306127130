import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import { Divider, Row, Col, Grid } from '@components/Grid';
import styles from './styles.module.scss';
import Img from 'gatsby-image';
import Button from '@components/Button';
import { Link } from 'gatsby';
import CustomSwiper from '@components/CustomSwiper';
import TooltipGlossary from '@components/TooltipGlossary';

const DesignAndDevelopment = (
    {
        imageSplash,
        imagePlatformBg,
        imagePlatformImage,
        sliderImages,
        badgesImage,
        psychologyImage,
        uiImage,
    },
    ref
) => {
    return (
        <section>
            <div className={styles.brandingSplashWrapper} ref={ref}>
                <div className={styles.websiteHeading}>
                    <Grid>
                        <Col xs={12}>
                            <h2>2. Website</h2>
                        </Col>
                    </Grid>
                </div>
                <Img fluid={imageSplash} imgStyle={{ objectFit: 'cover' }} />
            </div>

            <div className={styles.brandingPlatformWrapper}>
                <div className={styles.brandingPlatformBackground}>
                    <Img fluid={imagePlatformBg} imgStyle={{ objectFit: 'cover' }} />
                </div>

                <div className={styles.brandingPlatformContentWrapper}>
                    <Grid>
                        <Col xs={12}>
                            <div className={styles.brandingPlatformContent}>
                                <p>
                                    The worlds first gun-oriented social platform.
                                    <br /> A place to follow influencers, brands and vote for your
                                    favourites.
                                </p>
                            </div>
                        </Col>
                    </Grid>
                </div>

                <div className={styles.brandingPlatformImage}>
                    <Img fluid={imagePlatformImage} imgStyle={{ objectFit: 'cover' }} />
                </div>
            </div>

            <Grid>
                <Col xs={12}>
                    <Divider margin={5} />
                    <div className={styles.designAndDevelopmentHeading}>
                        <h2>
                            90% of Gundies Users are voting on mobile. Which is why an entire User
                            Flow journey was designed to ensure the mobile experience was fluid and
                            intuitive.
                        </h2>
                    </div>
                </Col>
            </Grid>

            <Divider margin={5} />
            <Divider xsMargin={0} smMargin={5} />

            <CustomSwiper>
                {sliderImages?.map((image) => (
                    <SwiperSlide key={image?.src}>
                        <div>
                            <Img fluid={image} imgStyle={{ objectFit: 'cover' }} />
                        </div>
                    </SwiperSlide>
                ))}
            </CustomSwiper>

            <Divider margin={5} />
            <Divider xsMargin={0} smMargin={5} />

            <Grid>
                <Row>
                    <Col xs={12} md={6}>
                        <p>
                            The UX of the Gundies was based on the premise of embodying a social
                            media platform. Techniques used in social media originally devised from
                            the gambling process of ‘instant gratification’. An element of this is
                            replicated and used on the Gundies website to encourage users to stay
                            and interact with the platform, which in turn, allows more viewing
                            coverage on the sponsorships. The longer the screen time , the high
                            chance of converting a user to familiarise themselves with the
                            sponsorship branding and becoming a potential customer.
                        </p>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className={styles.designAndDevelopmentBadges}>
                            <Img fluid={badgesImage} imgStyle={{ objectFit: 'contain' }} />
                        </div>
                    </Col>
                </Row>
            </Grid>

            <Divider margin={5} />
            <Divider margin={5} />
            <Divider xsMargin={0} smMargin={5} />

            <div className={styles.designAndDevelopmentPsychologyWrapper}>
                <Row end="xs">
                    <Col xs={12} lg={6}>
                        <Divider xsMargin={5} lgMargin={0} />
                        <div className={styles.designAndDevelopmentPsychologyImage}>
                            <Img
                                fluid={psychologyImage}
                                imgStyle={{ objectFit: 'cover', objectPosition: '90% 50%' }}
                            />
                        </div>
                    </Col>
                    <Col xs={12} lg={6}>
                        <div className={styles.designAndDevelopmentPsychologyContent}>
                            <h2>Using psychology to boost conversion rate</h2>
                            <p>
                                A large toolset that the Gundies can use is the influencers that
                                they display and host on their platform. Many of the users visit the
                                platform to solely to support their pre-discovered influencer. This
                                user already will have positive mental attitudes associated to this
                                individual. This is why we designed the voting{' '}
                                <Link to="#glossary=modals">modals</Link> (popups) to visually
                                cross-compare the sponsor branding with the influencer image.
                                <br /> With repetition over the 30 days of voting, that user will
                                begin to associate the values they evoke from the influencer to the
                                sponsorships visual iconography, enriching the sponsors brand in a
                                positive way.
                            </p>
                            <p>
                                Read more about how we can help you with Extended UX by following
                                the link below.
                            </p>
                            <Link to="/service/web-platforms/ux">
                                <Button text="Read more" />
                            </Link>
                        </div>
                    </Col>
                </Row>
            </div>

            <Divider margin={5} />
            <Divider margin={5} />

            <Grid>
                <Row>
                    <Col xs={12} md={6}>
                        <h2>
                            A new UI component kit was devised from scratch to keep The Gundies User
                            Interface (UI) organised.
                        </h2>
                        <p>Buttons, cards, input boxes, sliders, image guidelines,</p>
                        <p>
                            A complete digital brand at the touch of a button allows for quick
                            building on new Gundies web-pages.{' '}
                        </p>
                        <p>
                            There was a total of 60 repeatable components designed for The Gundies.
                        </p>
                    </Col>
                    <Col xs={12} md={6}>
                        <Img fluid={uiImage} imgStyle={{ objectFit: 'cover' }} />
                    </Col>
                </Row>
            </Grid>
        </section>
    );
};

export default React.forwardRef(DesignAndDevelopment);
