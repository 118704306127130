import React, { forwardRef } from 'react';
import { Divider, Row, Col, Grid } from '@components/Grid';
import styles from './styles.module.scss';
import Img from 'gatsby-image';
import Button from '@components/Button';
import KnowMoreCta from '@components/CaseStudies/KnowMoreCta';
import { Link } from 'gatsby';
import ScrollTextWithImage from '@components/ScrollTextWithImage';

const Marketing = (
    {
        imageGrid,
        imageGuidelines,
        imageTemp,
        imageAnimationAttention,
        imageEmailTemplates,
        gif01,
        gif02,
    },
    ref
) => {
    return (
        <section className={styles.marketingWrapper} ref={ref}>
            <Grid>
                <Row>
                    <Col xs={12}>
                        <h2>3. Marketing</h2>
                    </Col>
                </Row>

                <Divider margin={5} />
                <Divider xsMargin={0} smMargin={5} />

                <Row>
                    <Col xd={12}>
                        <div className={styles.marketingHeading}>
                            <h2>
                                The Gundies has a comprehensive social media schedule and assets.
                            </h2>
                        </div>
                    </Col>
                </Row>
            </Grid>
            <Divider margin={5} />

            <Img fluid={imageGrid} imgStyle={{ objectFit: 'cover' }} />

            <Divider margin={5} />
            <Divider xsMargin={0} smMargin={5} />

            <Grid>
                <Row>
                    <Col xs={12} md={6}>
                        <h2>
                            The company branding stems from a compressive set of guidelines detailed
                            in Forge Relations brand. This is to ensure The Gundies conveys itself
                            as professionally as possible.
                        </h2>
                    </Col>
                    <Col xs={12} md={6}>
                        <Img fluid={imageGuidelines} imgStyle={{ objectFit: 'cover' }} />
                    </Col>
                </Row>
            </Grid>

            {/*<Divider margin={5} />*/}
            {/*<Divider xsMargin={0} smMargin={5}/>*/}

            <div className={styles.marketingGifWrapper}>
                <img src={gif01} alt={'gun reloading gif'} />
            </div>

            <Divider margin={5} />
            {/*<Divider xsMargin={0} smMargin={5}/>*/}

            <Grid>
                <Row>
                    <Col xd={12}>
                        <div className={styles.marketingHeading}>
                            <h2>
                                Animation grabs the users attention 40% more than static graphics.
                            </h2>
                        </div>
                    </Col>
                </Row>
            </Grid>

            <Divider margin={5} />
            <Divider xsMargin={0} smMargin={5} />

            <div className={styles.marketingSecondGifWrapper}>
                <img src={gif02} alt={'gun reloading gif'} />
            </div>

            <KnowMoreCta
                text={
                    <h2>
                        Want to know more <br /> about Social Marketing?
                    </h2>
                }
                link="/service/graphic-design/marketing"
                buttonText="Read more"
            />

            <Divider margin={5} />
            <Divider xsMargin={0} smMargin={5} />

            <Grid>
                <ScrollTextWithImage imageFluid={imageEmailTemplates} imageLeft>
                    <h2>Email templates were easy to edit, clean & responsive.</h2>
                    <p>
                        Emails and templates are hand coded in MJML and have a confirmed
                        compatibility with all major email platforms. In total there were 10
                        email templates for The Gundies.
                    </p>
                    <p>Want to know more about our email design & development?</p>
                    <Link to="/service/graphic-design/rebranding">
                        <Button text="Read more" />
                    </Link>
                </ScrollTextWithImage>

                {/*<div className={styles.marketingRowWrapper}>*/}
                {/*    <Row>*/}
                {/*        <Col xs={12} md={6}>*/}
                {/*            <Img fluid={imageEmailTemplates} imgStyle={{ objectFit: 'cover' }} />*/}
                {/*        </Col>*/}
                {/*        <Col xs={12} md={6}>*/}
                {/*            <Divider xsMargin={5} lgMargin={0} />*/}
                {/*            <h2>Email templates were easy to edit, clean & responsive.</h2>*/}
                {/*            <p>*/}
                {/*                Emails and templates are hand coded in MJML and have a confirmed*/}
                {/*                compatibility with all major email platforms. In total there were 10*/}
                {/*                email templates for The Gundies.*/}
                {/*            </p>*/}
                {/*            <p>Want to know more about our email design & development?</p>*/}
                {/*            <Link to="/service/graphic-design/rebranding">*/}
                {/*                <Button text="Read more" />*/}
                {/*            </Link>*/}
                {/*            <Divider xsMargin={5} lgMargin={0} />*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</div>*/}
            </Grid>
        </section>
    );
};

export default forwardRef(Marketing);
