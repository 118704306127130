import React from 'react';
import Img from 'gatsby-image';
import { Col, Grid } from '@components/Grid';

import styles from './styles.module.scss';

const Hero = ({ title, text, background }) => {
    return (
        <div className={styles.hero}>
            <div className={styles.heroContentWrapper}>
                <Grid>
                    <Col xs={12}>
                        <div className={styles.heroContent}>
                            <h1>{title}</h1>
                            <p>{text}</p>
                        </div>
                    </Col>
                </Grid>
            </div>

            {!!background && (
                <div className={styles.heroImage}>
                    <Img fluid={background} imgStyle={{ objectFit: 'cover' }} />
                </div>
            )}
        </div>
    );
};

export default Hero;
