import React from 'react';
import styles from './styles.module.scss';
import Button from '@components/Button';
import Link from 'gatsby-link';

const StartProjectCta = () => {
    return (
        <div className="bg-white">
            <div className="max-w-6xl container px-5 xl:px-0 mx-auto py-24 text-center">
                <h2 className="text-black">Ready to start your next big project?</h2>
                <p className="text-2xl lg:text-2xl mt-8 font-medium text-black mx-auto max-w-4xl">
                    We’re friendly people, get in touch today for a free consultation to learn how
                    we can improve your business
                </p>
                <div className="mt-8 flex flex-col lg:flex-row justify-center items-center ">
                    <Link to="tel:01206259355">
                        <Button text="Phone us" />
                    </Link>

                    <a
                        rel="noreferrer noopener"
                        target="_blank"
                        href="https://calendly.com/elementsoftworks/15min"
                    >
                        <Button secondary text="Schedule phone call" />
                    </a>
                </div>
            </div>
        </div>
    );
};

export default StartProjectCta;
