import React from 'react';
import SVG from 'react-inlinesvg';
import { Divider, Row, Col, Grid } from '@components/Grid';
import styles from './styles.module.scss';
import { Link } from 'gatsby';

const Contents = ({ svg, svgAlt, list }) => {
    return (
        <section className={styles.contentsWrapper}>
            <Grid>
                <Row>
                    <Col xs={12} sm={6}>
                        <h2>Contents</h2>
                        <Divider margin={2} />
                        <ol className={styles.mainList}>
                            {list?.map((content, index) => (
                                <li key={`${content?.title}-${index}`}>
                                    <span
                                        className={styles.scrollDown}
                                        onClick={() => {
                                            console.log(content?.ref);
                                            content?.ref?.current?.scrollIntoView({
                                                behavior: 'smooth',
                                            });
                                        }}
                                    >
                                        {content?.title}
                                    </span>
                                    {!!content?.items && (
                                        <ul className={styles.subList}>
                                            {content?.items?.map((item, index) => (
                                                <li key={`${item}-${index}`}>
                                                    <p>{item}</p>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            ))}
                        </ol>
                    </Col>
                    <Col xs={12} sm={6}>
                        <Divider xsMargin={5} smMargin={0} />
                        <div className={styles.svgContainer}>
                            <SVG alt={svgAlt} src={svg} />
                        </div>
                    </Col>
                </Row>
            </Grid>
        </section>
    );
};

export default Contents;
