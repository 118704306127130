import React from 'react';
import SVG from 'react-inlinesvg';
import { Divider, Row, Col, Grid } from '@components/Grid';
import styles from './styles.module.scss';
import Img from 'gatsby-image';
import Button from '@components/Button';
import KnowMoreCta from '@components/CaseStudies/KnowMoreCta';
import Link from 'gatsby-link';
import TooltipGlossary from '@components/TooltipGlossary';

const BrandingStrategy = ({ imageBg, imageSplash, imagePlatformBg, imagePlatformImage}, ref) => {
    return (
        <section className={styles.brandingWrapper} ref={ref}>
            <Grid>
                <h2>1. Branding Strategy</h2>
                <Divider margin={2} />
                <Row>
                    <Col xs={12} md={6}>
                        <div className={styles.brandingHeading}>
                            <p>
                                <span>Primary Goal:</span> To bring the firearms community together
                                to celebrate by using ‘influencers’ as a medium, funded by company
                                sponsorships.
                            </p>
                            <p>
                                <span>Secondary Goal:</span>
                                To intertwine the influencer audience bases for user discovery.
                            </p>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className={styles.brandingContent}>
                            <p>
                                The Gundies is based on an award ceremony for to celebrate
                                firearm-centric personalities.
                            </p>
                            <p>
                                A web-platform is used to connect these figures where their
                                supportive audience can vote for them.
                            </p>
                            <p>
                                For The Gundies 2021 future sponsorship coverage was required to
                                ensure their satisfaction. This was achieved through extensive{' '}
                                <Link to="#glossary=UX">UX</Link> throughout the website.
                            </p>
                            <p>
                                An extensive social media & email marketing campaign was composed
                                with Forge Relations to ensure appropriate traffic for the website.
                            </p>
                        </div>
                    </Col>
                </Row>

                <Divider margin={5} />

                <Row>
                    <Col xs={12}>
                        <div className={styles.brandingBackgroundWrapper}>
                            <Img fluid={imageBg} imgStyle={{ objectFit: 'cover' }} />
                        </div>
                    </Col>
                </Row>
            </Grid>

            <Divider margin={5} />
            <Divider smMargin={0} mdMargin={5} />

            <KnowMoreCta
                text={
                    <h2>
                        Want to know more <br /> about Brand Strategy?
                    </h2>
                }
                buttonText="Read more"
                link="/service/graphic-design/branding-strategy"
            />

            <Divider margin={5} />
        </section>
    );
};

export default React.forwardRef(BrandingStrategy);

